import React from 'react';
//import miImagen from '../img/down-arrow.svg';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircleSharp';


const ArrowIcon = () => {
    return (
   
        <ArrowDropDownCircleIcon  style={{ 'z-index': '0', cursor: 'pointer',}} fontSize='small' color='primary'/>
      
    );
  };
  
  export default ArrowIcon;