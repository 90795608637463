import React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import "./footer.css";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#212121", color: "white" }}>
      <div className="container-fluid fw-bold p-2 pt-4 p-md-5">
        <div className="row m-0">
          <div className="col-md-3 col-12 mb-3 text-left">
            <Link href="https://www.tec.mx" target="_blank">
              <img
                src="https://admision.tec.mx/footer-tec-mx/img/HorizontalPrimarioBlanco.svg"
                className="logo"
                alt="Logo TEC"
              />
            </Link>
          </div>
          <div className="col-md-3 col-12 mb-3 content_links">
            <div className="mb-3">
              <Typography variant="h6" className="text-white">
                <b>Educación</b>
              </Typography>
              <Link
                href="https://tec.mx/es/prepatec/programas-academicos"
                target="_blank"
                className="sub-link pe-3"
                sx={{ textDecoration: "none" }}
              >
                PREPATEC
              </Link>
              <Link
                href="https://tec.mx/es/oferta-educativa-profesional"
                target="_blank"
                className="sub-link pe-3 "
                sx={{ textDecoration: "none" }}
              >
                PROFESIONAL
              </Link>
            </div>
            <div className="mb-3">
              <Typography variant="h6" className="text-white">
                Investigación
              </Typography>
              <Link
                href="https://tec.mx/es/investigacion/donde-se-realiza-la-investigacion"
                target="_blank"
                className="sub-link pe-3 text-uppercase"
                sx={{ textDecoration: "none" }}
              >
                Grupos
              </Link>
              <Link
                href="https://tec.mx/es/investigacion/investigadores"
                target="_blank"
                className="sub-link pe-3 text-uppercase"
                sx={{ textDecoration: "none" }}
              >
                Investigadores
              </Link>
            </div>
            <div className="mb-3">
              <Typography
                variant="h6"
                className="text-white"
                sx={{ textDecoration: "none" }}
              >
                Vinculación
              </Typography>
              <Link
                href="https://tec.mx/es/filantropia"
                target="_blank"
                className="sub-link pe-3 text-uppercase"
                sx={{ textDecoration: "none" }}
              >
                Filantropía
              </Link>
              <Link
                href="https://tec.mx/es/florecimiento-humano"
                target="_blank"
                className="sub-link pe-3 text-uppercase"
                sx={{ textDecoration: "none" }}
              >
                Florecimiento Humano
              </Link>
            </div>
            <div className="mb-4">
              <Link
                href="https://tec.mx/es/conocenos"
                target="_blank"
                className="text-white footer_links fs-4"
                sx={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  className="text-white"
                  sx={{ textDecoration: "none" }}
                >
                  Conócenos
                </Typography>
              </Link>
              <Link
                href="https://store.tec.mx/"
                target="_blank"
                className=" footer_links text-uppercase pe-3 sub-link"
                sx={{ textDecoration: "none" }}
              >
                Tec Store
              </Link>
              <Link
                href="https://tec.mx/es/venues"
                target="_blank"
                className=" footer_links text-uppercase sub-link pe-3"
                sx={{ textDecoration: "none" }}
              >
                Venues
              </Link>
              <Link
                href="https://tec.mx/es/newsroom"
                target="_blank"
                className=" footer_links text-uppercase sub-link pe-3"
                sx={{ textDecoration: "none" }}
              >
                Newsroom
              </Link>
            </div>
          </div>

          <div className="col-md-3 col-6 content_links">
            <div className="mb-3">
              <Link
                href="https://research.tec.mx/vivo-tec/"
                target="_blank"
                className="text-white footer_links fs-6"
                sx={{ textDecoration: "none" }}
              >
                <Typography variant="h6" className="text-white">
                  Profesores
                </Typography>
              </Link>
            </div>
            <div className="mb-3">
              <Link
                href="https://tec.mx/es/exatec"
                target="_blank"
                className="text-white footer_links fs-6"
                sx={{ textDecoration: "none" }}
              >
                <Typography variant="h6" className="text-white">
                  Egresados
                </Typography>
              </Link>
            </div>
            <div className="mb-3">
              <Link
                href="https://conecta.tec.mx/es"
                target="_blank"
                className="text-white footer_links fs-6"
                sx={{ textDecoration: "none" }}
              >
                <Typography variant="h6" className="text-white">
                  Noticias
                </Typography>
              </Link>
            </div>
          </div>

          <div className="col-md-3 col-6 mb-3">
            <Typography variant="h6" className="text-white mb-3">
              Síguenos
            </Typography>
            <ul className="inline-menu mb-4">
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.facebook.com/tecdemonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <FacebookIcon className="link-icon facebook" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://twitter.com/tecdemonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <XIcon className="link-icon twitter" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/company/itesm"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <LinkedInIcon className="link-icon linkedin" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.youtube.com/TECdeMonterrey"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <YouTubeIcon className="link-icon youtube" />
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  target="_blank"
                  href="https://www.instagram.com/tecdemonterrey/"
                  sx={{ color: "white", margin: "0px !important" }}
                >
                  <InstagramIcon className="link-icon instagram" />
                </Link>
              </li>
            </ul>
            <Link
              href="https://tec.mx/es/contactanos"
              target="_blank"
              className="text-white"
              sx={{ textDecoration: "none" }}
            >
              <span className="siguenos-contacto fw-bold text-uppercase sub-link">
                Contáctanos
              </span>
            </Link>
            <br />
            <Link
              href="https://jobs.tec.mx/content/tecnologico-de-monterrey/?locale=es_MX"
              target="_blank"
              className="text-white"
              sub-link
              sx={{ textDecoration: "none" }}
            >
              <span className="siguenos-contacto fw-bold text-uppercase sub-link">
                Únete a nuestro equipo
              </span>
            </Link>
          </div>
        </div>
        <div className="row mt-3 m-0">
          <div className="col-12">
            <span className="footer_links text-white">MIEMBRO DE:</span>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12">
            <div className="footer_miembros">
              <Link href="https://alianzapacifico.net/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Alianza del Pacífico"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Alianza-del-Pacifico.png"
                />
              </Link>
            </div>
            <div className="footer_miembros text-start">
              <Link href="https://apru.org/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Asociación de Universidades de la Cuenca del Pacífico"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Asociacion-de-Universidades-de-la-Cuenca-del-Pacifico.png"
                />
              </Link>
            </div>
            <div className="footer_miembros">
              <Link href="https://cinda.cl/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Centro Interuniversitario de Desarrollo"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Centro-Interuniversitario-de-Desarrollo.png"
                />
              </Link>
            </div>
            <div className="footer_miembros">
              <Link href="https://www.eciu.org/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Consorcio Europeo de Universidades Innovadoras"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Consorcio%20Europeo-de-Universidades-Innovadoras.png"
                />
              </Link>
            </div>
            <div className="footer_miembros text-start">
              <Link href="https://www.magalhaes-network.org/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Red Magalhaes"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Red-Magalhaes.png"
                />
              </Link>
            </div>
            <div className="footer_miembros">
              <Link href="https://universitas21.com/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Universitas 21"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Universitas-21.png"
                />
              </Link>
            </div>
            <div className="footer_miembros">
              <Link href="https://wun.ac.uk/" target="_blank">
                <img
                  className="footer_miembros_img"
                  alt="Red Mundial de Universidades"
                  src="https://tec.mx/sites/default/files/repositorio/Logos_footer/Red-Mundial-de-Universidades.png"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Container maxWidth="xl" className="p-2 pb-4 px-5 pt-md-4 pb-md-4">
        <Grid container>
          <Grid item xs={12} className="text-center">
            <Typography
              variant="body1"
              className="mb-2"
              sx={{ fontSize: "12px", fontWeight: "700" }}
            >
              AV. EUGENIO GARZA SADA 2501 SUR COL. TECNOLÓGICO C.P. 64700 |
              MONTERREY, NUEVO LEÓN, MÉXICO | TEL. +52 (81) 8358-2000 D.R.©
              INSTITUTO TECNOLÓGICO Y DE ESTUDIOS SUPERIORES DE MONTERREY,
              MÉXICO. {new Date().getFullYear()}
            </Typography>
            <Typography
              variant="caption"
              style={{ fontSize: "8px", fontWeight: 400 }}
              className="mb-2"
            >
              *DEC-520912 PROGRAMAS EN MODALIDAD ESCOLARIZADA.
            </Typography>
          </Grid>
          <Grid item xs={12} className="text-center">
            <Typography
              variant="body1"
              className="text-uppercase"
              sx={{ fontSize: "12px", fontWeight: "700" }}
            >
              <Link
                href="https://tec.mx/es/aviso-legal"
                target="_blank"
                className="text-white mr-1"
                sx={{ textDecoration: "none" }}
              >
                Aviso legal
              </Link>{" "}
              |
              <Link
                href="https://tec.mx/es/politicas-de-privacidad-del-tecnologico-de-monterrey"
                target="_blank"
                className="text-white ml-1 mr-1"
                sx={{ textDecoration: "none" }}
              >
                Políticas de privacidad
              </Link>{" "}
              |
              <Link
                href="https://tec.mx/es/avisos-de-privacidad"
                target="_blank"
                className="text-white ml-1 mr-1"
                sx={{ textDecoration: "none" }}
              >
                Aviso de privacidad
              </Link>{" "}
              |
              <Link
                href="https://tec.mx/es/sobre-el-sitio"
                target="_blank"
                className="text-white ml-1"
                sx={{ textDecoration: "none" }}
              >
                Sobre el sitio
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
