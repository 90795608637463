import "./Evento.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import Box from "@mui/joy/Box";
import { urlRoot } from "../global";


function EventoDatos({ Evento, Registrado, InfoCampus }) {

  var ruta = window.location.origin;
  var palabra = "localhost";
  var index = ruta.indexOf(palabra);
  if (index < 0) ruta = ruta + "/" + urlRoot;

  //const keymap = "";


  return (
    <Box style={{"background": "#f8f8f8"}} sx={{ flexGrow: 1, paddingLeft: "8%", paddingRight: "8%" }}>
      <Grid container spacing={2}>
        {Evento.map((eventos) => (
          <>
            {/*Datos de contacto*/}
            
              {InfoCampus.map((informacionC) => (
                <>
               {(informacionC.admissions_number || informacionC.admissions_email) && (
                                <>

                <Grid item md={6} lg={6} xs={12}>
                <div className="contact_information">
   

                  <h2 className="tituloInfo"> Datos de contacto</h2>
                  {informacionC.admissions_number && (
                  <div className="info-container">
                    <br></br>
                    <span>
                      <PhoneIcon />
                    </span>

                    <Box
                      sx={{
                        display: { xs: "none", md: "block" },
                        width: "100%",
                      }}
                    >
                      <p className="gerytext">
                        {informacionC.admissions_number}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "block", md: "none" },
                        width: "100%",
                      }}
                    >
                      <p className="gerytext">
                        <a
                          style={{ textDecoration: "none" }}
                          href={`tel:${informacionC.admissions_number.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          {informacionC.admissions_number}
                        </a>
                      </p>
                    </Box>
                    
                  </div>
                  )}

                  {informacionC.admissions_email && (
                  <div className="info-container">
                    <span>
                      <EmailIcon />
                    </span>

                    <p className="gerytext" style={{ cursor: "pointer" }}>
                      <a className="gerytext"
                        href={`mailto:${informacionC.admissions_email}`}
                        style={{ textDecoration: "none" }}
                      >
                        {informacionC.admissions_email}
                      </a>
                    </p>
                  </div>
                  )}

                  <div className="info-container">
                    <br />
                  </div>
                  </div>
                  </Grid>
                </>
                     )}
                      </>
                ))}
          
            
            {/*MAPA*/}
            <Grid item md={6} lg={6} xs={12} sx={{ height: "50%" }}>
              {eventos.event.experience === "presencial" ? (
                <div>
                  {eventos.event.ubicacion !== "" && (
                    <div>
                      <h2 className="tituloInfo"> Ubicación</h2>
                      {eventos.event.ubicacion !== null && (
                        <iframe
                          className="ubi frameDisenio"
                          title="presencial"
                          width="600"
                          height="250"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAaOS-wzdp9p1lzw1LGqcxbOXAG60UE124&q=${eventos.event.ubicacion}`}
                        ></iframe>
                      )}
                      <div style={{"fontSize":"20px"}} >{eventos.event.ubicacion}</div>

                      <p style={{ cursor: "pointer" }}>
                      <a className="tituloInfo"
                      target="_blank"
                      rel="noreferrer"
                        href={`https://www.google.com/maps/search/${eventos.event.ubicacion}`}
                        style={{ textDecoration: "none" }} >
                        Ver en el Mapa{`>`} 
                      </a>
                    </p>
                    </div>
                  )}
                </div>
              ) : null}
              {eventos.event.experience === "hibrido" ? (
                <div className="map_information">
                  {Registrado === true ? (
                    <p className="fechaEvent">{eventos.event.stream_url}</p>
                  ) : null}
                  <iframe
                    className="ubi"
                    title="hibrido"
                    width="600"
                    height="450"
                    loading="lazy"
                    allowfullscreen
                    referrerpolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAaOS-wzdp9p1lzw1LGqcxbOXAG60UE124&q=${eventos.event.ubicacion}`}
                  ></iframe>
                </div>
              ) : null}
            </Grid>
			
			
			          
          </>
        )) 
        }
      </Grid>
    </Box>
  );
}

export { EventoDatos };
