import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/NavbarSimple";
import { EventoDestacado } from "../../components/EventoDestacado";
import { apiUrlGestor, apiUrlAced, urlRoot } from "../../global";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Footer from "../../components/Footer";
import LogoError from "../../img/error404.svg";
import { useTheme } from "@mui/material/styles";

function callData(nivel, campusId, setEventos, path) {

  var nivelConsulta="05";
  if(nivel==="prepatec")
  nivelConsulta="03";

  setEventos([]);
  axios
    .post(apiUrlAced + "campuses/search", {
      grade: nivel,
      name: campusId,
    })
    .then(function (response) {
      const campusData = response.data.data;
      axios
        .get(
          apiUrlGestor +
            "obtenerEventosError.php?c=" +
            campusData[0]["sf_key"] +
            "&n=" + nivelConsulta +
            "&b=" +
            path +
            "&cuantos=3"
        )
        .then(function (response) {
          console.log(
            apiUrlGestor +
              "obtenerEventosError.php?c=" +
              campusData[0]["sf_key"] +
              "&n=" + nivelConsulta +
              "&b=" +
              path +
              "&cuantos=3"
          );
          console.log(response.data.events);
          const eventoData = response.data.events;
          setEventos(eventoData); // Actualizar el estado con los datos recibidos
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    .catch(function (error) {
      console.log(error);
    });
}

function Error404({ path }) {
  const theme = useTheme();
  const handleCampusChangeInParent = (selectedCampus, nivel) => {
    console.log(
      "Valor seleccionado del campus en el componente padre:",
      selectedCampus
    );
    console.log("Valor seleccionado del campus en el componente padre:", nivel);

    // Actualizar el estado 'eventos' con los nuevos datos del campus seleccionado
    callData(nivel, selectedCampus, setEventos, path);
  };

  // let { campusId, nivel } = useParams();
  const [campus, setCampus] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [eventos, setEventos] = useState([]); // Estado del array
  useEffect(() => {
    moment.locale("es");
    // Llamar a la función callData y pasar setEventos para actualizar el estado
    const campusFromLocalStorage = localStorage.getItem("campusStorageName");
    setCampus(localStorage.getItem("campusStorageName"));
    setSelectedGrade(localStorage.getItem("nivelStorage"));
    if (campusFromLocalStorage) {
      callData(
        localStorage.getItem("nivelStorage"),
        localStorage.getItem("campusStorageName"),
        setEventos,
        path
      );
    } else {
      setSelectedGrade("profesional");
      setCampus("Monterrey");
      callData("profesional", "Monterrey", setEventos, path);
    }

    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  var ruta = window.location.origin;
  var palabra = "localhost";

  var index = ruta.indexOf(palabra);
  //console.log(ruta);
  if (index < 0) ruta = ruta + "/" + urlRoot;

  return (
    <div>
      <Navbar
        onChangeCampus={handleCampusChangeInParent}
        setEventos={setEventos}
        nivel={selectedGrade}
        __campus={campus}
      />
      <Grid item xs={12} sx={{ mb: 6 }}>
        <Grid
          item
          xs={12}
          sx={{
            mb: 6,
            backgroundImage: `url(${`${process.env.PUBLIC_URL}/img/banners/banner-default.webp`})`,
            backgroundSize: "cover",
            boxShadow: `1px 30px 30px 10px #bfbfbf`,
            backgroundPosition: "center",
            height: 140,
          }}
        >
          {" "}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div>{/* Filtros */}</div>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid item xs={12}>
              <Typography component="div" sx={{ mb: 2.5 }}>
                <span style={{ color: "#3393FD", fontSize: "20px" }}></span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={LogoError}
              alt="Logo error 404"
              style={{
                width: "15%",
                [theme.breakpoints.down("sm")]: {
                  width: "30%",
                },
              }}
            />

            <Typography component="div" sx={{ mb: 2.5 }}>
              <span style={{ color: "#000000", fontSize: "40px" }}>
                <b>Error 404</b>
              </span>
            </Typography>

            <Typography
              component="div"
              sx={{ mb: 2.5, textAlign: "center", marginTop: "-25px" }}
            >
              <div class="row m-0" style={{ justifyContent: "center" }}>
                <div class="col-11 col-md-12">
                  <span style={{ color: "#3393FD", fontSize: "20px" }}>
                    ¡Oops, parece que no hemos encontrado el evento que buscas!
                  </span>
                </div>
              </div>

              <br></br>
              <div class="row m-0" style={{ justifyContent: "center" }}>
                <div class=" col-md-6 col-sm-10 col-11 pt-3">
                  <span style={{ color: "black", fontSize: "20px" }}>
                    No te preocupes, pueden surgir cambios en el calendario. Te
                    sugerimos explorar nuestras otras opciones disponibles y
                    estar atento a futuras actualizaciones. ¡Tu interés es
                    valioso para nosotros y esperamos tenerte en nuestros
                    próximos eventos!
                  </span>
                </div>
              </div>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "2%", display: "flex", justifyContent: "center" }}
        >
          <Grid item container xs={10}>
            <Grid item xs={12}>
              <Typography component="div" sx={{ mb: 2.5 }}>
                <span style={{ color: "#000", fontSize: "30px" }}>
                  Tal vez te puede interesar asistir a:
                </span>
              </Typography>
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {eventos.map((evento, key) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ paddingLeft: "10px", paddingTop: "10px" }}
                    key={evento.event.id}
                  >
                    <EventoDestacado
                      key={key}
                      nombreEvento={evento.event.title}
                      lugarEvento={evento.event.ubicacion || 'ubicación'}
                      urlEvento={evento.event.urlname}
                      urlImg={evento.event.imagen}
                      sdg=""
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "5%" }}>
        <Footer nivel={selectedGrade}></Footer>
      </div>
    </div>
  );
}

export default Error404;

// ESTO DE ABAJO SIRVE, NO BORRAR Y BASARSE EN ESE CODIGO SI ALGO NO FUNCIONA DE LO DE ARRIBA

// const Error404 = ({ path }) => {
//   return (
//     <div>
//       <h1>404 - Ruta no encontrada</h1>
//       <p>
//         La ruta <code>{path}</code> no existe.
//       </p>
//     </div>
//   );
// };

// export default Error404;
