import React from "react";
import "./Banner.css";

const Banner = ({ imgName, campus }) => {
  const scrollToSection = (sectionId) => {
    document.location.href = "#" + sectionId;
  };

  return (
    <div
      className="contenedor"
      style={{
        backgroundImage: `url(${
          imgName
            ? `${process.env.PUBLIC_URL}/img/banners/${imgName}`
            : `${process.env.PUBLIC_URL}/img/banners/banner-evento1.webp`
        })`,
      }}
    >
      <div className="divobscuro"></div>

      <div className="container contenefinal">
        <div
          className="row"
          style={{
            justifyContent: "center",
            display: campus === "off" ? "none" : "flex",
          }}
        >
          <div className="col-md-12 col-lg-6 d-flex flex-column align-items-center">
            <p className="titulo">Calendario de {campus}</p>
            <p className="descripcion">Eventos para Admisiones y Becas</p>
            <div className="descubremas">
              <button onClick={() => scrollToSection("inicio")}>
                Descubre más
              </button>
            </div>
          </div>
          {/* <div className="buscador">
          <input type="text" placeholder="Busca lugares, eventos, fechas..." />
          <div className="botonbuscar">
            <button type="submit">
              <span className="searchicon">
                <FaSearch />
              </span>
              Encuentra
            </button>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
