import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { obtenerCampus } from "../../helper";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.extend(customParseFormat);

function FiltrosComponent({
  onChangeCampus,
  addChip,
  removeChipByType,
  buttonClicked,
  __nivel,
  __campus,
}) {
  dayjs.locale("es");
  const [campus, setCampus] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedNivel, setNivel] = useState({});
  const [datosCargados, setDatosCargados] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate) {
      if (endDate.year() === date.year()) {
        if (endDate.month() === date.month()) {
          let fechaChip =
            "Del " +
            date.date() +
            " al " +
            endDate.date() +
            " de " +
            date.locale("es").format("MMMM");
          // addChip(fechaChip, "fecha");
          addChip(
            fechaChip,
            "fecha",
            null,
            null,
            date.format("YYYY/MM/DD") + "-" + endDate.format("YYYY/MM/DD")
          );
        } else {
          let fechaChip =
            "Del " +
            date.date() +
            " de " +
            endDate.locale("es").format("MMMM") +
            " al " +
            date.date() +
            " de " +
            date.locale("es").format("MMMM");
          addChip(
            fechaChip,
            "fecha",
            null,
            null,
            date.format("YYYY/MM/DD") + "-" + endDate.format("YYYY/MM/DD")
          );
        }
      } else {
        let fechaChip =
          "Del " +
          date.format("DD/MM/YYYY") +
          " al " +
          endDate.format("DD/MM/YYYY");
        addChip(
          fechaChip,
          "fecha",
          null,
          null,
          date.format("YYYY/MM/DD") + "-" + endDate.format("YYYY/MM/DD")
        );
      }
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate.year() === date.year()) {
      if (startDate.month() === date.month()) {
        let fechaChip =
          "Del " +
          startDate.date() +
          " al " +
          date.date() +
          " de " +
          date.locale("es").format("MMMM");
        // addChip(fechaChip, "fecha");
        addChip(
          fechaChip,
          "fecha",
          null,
          null,
          startDate.format("YYYY/MM/DD") + "-" + date.format("YYYY/MM/DD")
        );
      } else {
        let fechaChip =
          "Del " +
          startDate.date() +
          " de " +
          startDate.locale("es").format("MMMM") +
          " al " +
          date.date() +
          " de " +
          date.locale("es").format("MMMM");
        addChip(
          fechaChip,
          "fecha",
          null,
          null,
          startDate.format("YYYY/MM/DD") + "-" + date.format("YYYY/MM/DD")
        );
      }
    } else {
      let fechaChip =
        "Del " +
        startDate.format("DD/MM/YYYY") +
        " al " +
        date.format("DD/MM/YYYY");
      addChip(
        fechaChip,
        "fecha",
        null,
        null,
        startDate.format("YYYY/MM/DD") + "-" + date.format("YYYY/MM/DD")
      );
    }
  };

  const handleChangeNivel = (event) => {
    setDatosCargados(false);
    setCampus([]);
    setSelectedValues("");
    const selectedNivel = event.target.value;
    setNivel(selectedNivel);
    fetchData(selectedNivel);
    addChip(selectedNivel, "nivel");
    setDatosCargados(true);
  };
  const handleChangeExpand = () => {
    setDatosCargados((prevState) => !prevState); // Alternar el estado de datosCargados
  };
  useEffect(() => TriggeredFunc(buttonClicked), [buttonClicked]);
  const TriggeredFunc = (test) => {
    console.log("test.tipo -------");
    console.log(test.tipo);
    switch (test.tipo) {
      case "nivel":
        setDatosCargados(false);
        setNivel("");
        setCampus([]);
        break;
      case "campus":
        setSelectedValues("");
        break;
      case "fecha":
        setStartDate(null);
        setEndDate(null);
        break;

      default:
        break;
    }
  };
  const onPageLoad = async () => {
    console.log("entro componente");
    const selectedNivel = localStorage.getItem("nivelStorage");
    setNivel(selectedNivel);
    setDatosCargados(true);
    await fetchData2(selectedNivel); // Espera a que se completen las solicitudes de datos
  };
  const fetchData2 = async (selectedNivel) => {
    try {
      let cd = await obtenerCampus(selectedNivel);
      setCampus(await obtenerCampus(selectedNivel));
      setDatosCargados(true);
      const __selectedValue = localStorage.getItem("campusStorage");
      const selectedCampus = cd.find(
        (campusItem) => campusItem.sf_key === __selectedValue
      );
      if (selectedCampus) {
        const campusName = selectedCampus.name;
        setSelectedValues(__selectedValue);
        const selectedNivel = localStorage.getItem("nivelStorage");
        const newChip = [
          {
            titulo: selectedNivel,
            tipo: "nivel",
          },
          {
            titulo: campusName,
            tipo: "campus",
          },
        ];
        addChip("", "", "test", newChip);
      }
    } catch (error) {
      console.error("Error fetching campuses:", error);
      // Manejar el error aquí
    }
  };

  useEffect(() => {
    if (__nivel) {
      if (__campus) {
        onPageLoad();
      }
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [__nivel, __campus]);

  const handleChangeCampus = (event) => {
    const selectedValue = event.target.value;
    setSelectedValues(selectedValue);
    const selectedCampus = campus.find(
      (campusItem) => campusItem.sf_key === selectedValue
    );
    if (selectedCampus) {
      const campusName = selectedCampus.name;
      addChip(campusName, "campus");
    }
  };

  const fetchData = async (selectedNivel) => {
    try {
      let cd = await obtenerCampus(selectedNivel);
      setCampus(await obtenerCampus(selectedNivel));
      console.log("fetchData");
      console.log(cd);
      setDatosCargados(true);
    } catch (error) {
      console.error("Error fetching campuses:", error);
      // Manejar el error aquí
    }
  };
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid
              container
              direction={isSmallScreen ? "column" : "row"}
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <DatePicker
                  defaultValue={null}
                  value={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="YYYY-MM-DD"
                  label="Del"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  defaultValue={null}
                  value={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="YYYY-MM-DD"
                  label="Al"
                  minDate={startDate}
                  fullWidth
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel1-content`}
              id={`panel1-header`}
            >
              <Typography
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                <FormatListBulletedIcon />
                Nivel
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={selectedNivel}
                onChange={(event) => handleChangeNivel(event)}
              >
                <FormControlLabel
                  value={"profesional"}
                  control={<Radio />}
                  label={"Profesional"}
                />
                <FormControlLabel
                  value={"prepatec"}
                  control={<Radio />}
                  label={"PrepaTec"}
                />
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Accordion
        expanded={datosCargados} // Utilizar datosCargados como valor de expanded
        onChange={handleChangeExpand}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls={`panel2-content`}
          id={`panel2-header`}
        >
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            <FormatListBulletedIcon />
            Campus
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            value={selectedValues}
            onChange={(event) => handleChangeCampus(event, event.value)}
          >
            {campus.map((campusItem, idx) => (
              <FormControlLabel
                key={idx}
                value={campusItem.sf_key}
                control={<Radio />}
                label={campusItem.name}
              />
            ))}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FiltrosComponent;
