import { useEffect, useState  } from 'react';
import { apiUrlGestor } from "../global";
import sleep from "sleep-promise";
import { useNavigate } from "react-router-dom";

function FormSend({
    Evento,
    emailInput
}) {

    const [fetchComplete, setFetchComplete] = useState(false);

    let navigate = useNavigate();
    useEffect(() => {

        if (!fetchComplete) {

    const utm_source_input = localStorage.getItem("utm_source_Local");
    const utm_medium_input = localStorage.getItem("utm_medium_Local");
  //  const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");
    const utm_term_input = localStorage.getItem("utm_term_Local");

    console.table(Evento[0].event.cmpa);


    const credentials = {
        email: emailInput,
        cmpa: Evento[0].event.cmpa,
        evento:Evento[0].event.fonteva,
        nombreRegistro: "Evento-"+Evento[0].event.id_evento,
        landing: "Evento-"+Evento[0].event.id_evento,
        utm_source: utm_source_input,
        utm_term: utm_term_input,
        utm_medium: utm_medium_input,
        sendhubspot: "true",
        sendsalesforce: "true",
      };

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(credentials) };
        fetch(apiUrlGestor + "enviaRegistro.php", options)
        .then((response) => response.json())
        .then(sleep(2000))
        .then((data) => {
          console.log(data);
          localStorage.removeItem("utm_cmpa_Local");
          localStorage.removeItem("utm_term_Local");
          localStorage.removeItem("utm_medium_Local");
          localStorage.removeItem("utm_source_Local");
          localStorage.removeItem("expiracion");
          navigate(
            "../evento/" + Evento[0].event.urlname + "/gracias?g=2#confirmacion"
          );
          setFetchComplete(true);
        })
        .catch((error) => {
          console.log(error);
        });
        console.table(credentials);
    }//END IF fetchComplete

      //if(emailInput)


}, [Evento, emailInput, navigate, fetchComplete]);

return (
    <>
    {!fetchComplete && (
<input
    className={`btnreg sendBottom`}
    type="submit"
    value="Enviando..."
    disabled="true"
  />

  )}
</>

  ); }

  export { FormSend };