import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { apiUrlAced, urlRoot } from "../global";

export default function Campus({
  nombreEvento,
  subtituloEvento,
  urlImg,
  fechaEvento,
  ubicacionEvento,
  idEvento,
  areaEvent,
}) {
  const [areaData, setAreaData] = useState([]);

  useEffect(() => {
    if (areaEvent) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(apiUrlAced + "areas/" + areaEvent, options)
        .then((response) => response.json())
        .then((datos) => {
          setAreaData(datos.data.color);
        });
    }
  }, [areaEvent]);

  return (
    <Card
      sx={{
        mb: 2,
        mt: 3,
        backgroundColor: "#F7F7F7",
        borderRadius: "20px",
        padding: 0,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ margin: 0 }}>
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: "auto",
              borderRadius: "20px",
            }}
            image={
              urlImg
                ? `${process.env.PUBLIC_URL}/img/eventos/${urlImg}`
                : `${process.env.PUBLIC_URL}/img/test.jpg`
            }
            alt="test"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CardContent>
            <Typography
              sx={{
                typography: "h4",
                mb: 2.5,
                "@media (max-width: 600px)": {
                  fontSize: "1.5rem", // Ajusta el tamaño del texto para pantallas pequeñas
                },
              }}
            >
              <b>{nombreEvento}</b>
            </Typography>
            <Typography
              sx={{
                typography: "subtitle1",
                color: "text.secondary",
                mb: 2.5,
                "@media (max-width: 600px)": {
                  fontSize: "1rem", // Ajusta el tamaño del texto para pantallas pequeñas
                  whiteSpace: "nowrap", // Evita el desbordamiento del texto
                  overflow: "hidden", // Oculta el texto que se desborda
                  textOverflow: "ellipsis", // Agrega puntos suspensivos (...) al texto recortado
                },
              }}
            >
              <b dangerouslySetInnerHTML={{ __html: subtituloEvento }} />
              
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // En móvil columna, en otros casos fila
                alignItems: "center", // Centra verticalmente los elementos
                justifyContent: "center", // Centra horizontalmente los elementos
              }}
            >
              <Typography
                variant="subtitle2"
                color=""
                component="div"
                sx={{
                  mb: { xs: 1, md: 0 }, // Espacio inferior solo en móvil
                  mr: { xs: 0, md: 5 }, // Espacio derecho en móvil, en otros casos 5
                  fontSize: { xs: "0.8rem", md: "inherit" }, // Tamaño de fuente móvil
                }}
              >
                <AccessTimeRoundedIcon />
                <b>{fechaEvento}</b>
              </Typography>
              <Typography
                variant="subtitle2"
                color=""
                component="div"
                sx={{
                  ml: { xs: 0, md: 5 }, // Espacio izquierdo en móvil, en otros casos 5
                  fontSize: { xs: "0.8rem", md: "inherit" }, // Tamaño de fuente móvil
                }}
              >
                <LocationOnIcon />
                <b>{ubicacionEvento}</b>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "initial" },
                mt: 2,
              }}
            >
              <Button
                style={{ backgroundColor: areaData }}
                href={urlRoot==="gestor_eventos" ? "/" + urlRoot + "/evento/" + idEvento : "/evento/" + idEvento }
                color="success"
                variant="contained"
              >
                Ver más detalles
              </Button>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export { Campus };
