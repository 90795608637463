import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";
import { urlRoot } from "../global";

function Navbar({ tituloEvento, nivel }) {
  var ruta = window.location.origin;
  var palabra = "localhost";

  var index = ruta.indexOf(palabra);
  //console.log(ruta);
  if (index < 0) ruta = ruta + "/" + urlRoot;
  
  //console.log(ruta);
  useEffect(() => {
    let tituloEventoTxt=tituloEvento

    if(tituloEvento===undefined)
    tituloEventoTxt="Evento no encontrado"

    document.title = `${tituloEventoTxt}`;
  }, [tituloEvento]);
  return (
    <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <a href={"" + ruta + ""} rel="noopener noreferrer">
              <CardMedia
                component="img"
                sx={{ width: "100%" }}
                image={ruta + "/img/logo-" + nivel + ".svg"}
                alt="logo"
              />
            </a>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <a href={"" + ruta + ""} rel="noopener noreferrer">
              <CardMedia
                component="img"
                sx={{ width: 120 }}
                image={ruta + "/img/logo-" + nivel + ".svg"}
                alt="logo"
              />
            </a>
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyConten: "space-around",
            }}
          >
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex", lg: "flex" },
                justifyConten: "flex-end",
                alignItems: "center",
              }}
            ></Box>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export { Navbar };
