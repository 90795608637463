import AppRouter from "./routes/AppRouter"
import { BrowserRouter as Router} from "react-router-dom"
import { urlRoot } from "./global";

function App() {
  
  return (
    <div className="App">

    <Router basename={'/'+urlRoot}>
    <AppRouter />
  </Router>
</div>
  );
}

export default App;
