import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaRegClock,
  FaLocationArrow,
} from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const CarouselComponent = ({ eventos }) => {
  const [eventoSeleccionado, setEventoSeleccionado] = useState(eventos[0]);
  const sliderRef = useRef(null);

  const handleClickEvento = (evento) => {
    setEventoSeleccionado(evento);
  };
  const isMoreThanThreeEvents = eventos.length >= 3;

  const DateDisplay = ({ date }) => {
 
    const formattedDate = new Date(date);
      // Obtener el día, el mes y el año por separado
      const day = formattedDate.getDate();
      const locale = "es";
      const dayOfWeek = formattedDate.toLocaleString(locale, { weekday: "long" });
      const month = formattedDate.toLocaleString(locale, { month: "long" });
      const year = formattedDate.getFullYear();
      const hours = formattedDate.getHours();
      const minutes = formattedDate.getMinutes();
      
      // Convierte la hora a formato de 12 horas
      const hour12 = hours % 12 || 12;
      let hourShow=hour12;
      if(hourShow < 10 )
      hourShow="0"+hourShow;
  
      let minutesShow=minutes;
      if(minutesShow < 10)
      minutesShow="0"+minutesShow;
  
  
    return (

        <p>{`${dayOfWeek} ${day} de ${month} de ${year}`}</p>
      
    );
  };


  function FaArrowCircleLeft(props) {
    const { className, style, onClick } = props;
    return (
      <div id="prevID" >
        <svg stroke="currentColor" 
        fill="currentColor" 
        stroke-width="0" 
        viewBox="0 0 512 512" 
        class="slick-arrow" 
        className={className}
        style={{ ...style}}
        onClick={onClick}
        data-role="none" 
        currentSlide="1" 
        slideCount="2" 
        height="1em" 
        width="1em" 
        xmlns="http://www.w3.org/2000/svg" >
          <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"></path>
        </svg>
        
      </div>
    );
  }

  function FaArrowCircleRight(props) {
    const { className, style, onClick } = props;
    return (
      <div id="nextID" >
        <svg stroke="currentColor" 
        fill="currentColor" 
        stroke-width="0" 
        viewBox="0 0 512 512" 
        class="slick-arrow" 
        className={className}
        style={{ ...style}}
        onClick={onClick}
        data-role="none" 
        currentSlide="1" 
        slideCount="2" 
        height="1em" 
        width="1em" 
        xmlns="http://www.w3.org/2000/svg" >
          <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"></path>
        </svg>
        
      </div>
    );
  }

  const settings = {
    className: `center carousel`,
    centerMode: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    prevArrow: <FaArrowCircleLeft className="slick-prev"/>,
    nextArrow: <FaArrowCircleRight className="slick-next" />,
    adaptativeHeight: true,
    focusOnSelect: true,
    afterChange: (currentSlide) => {
      setEventoSeleccionado(eventos[currentSlide]);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          prevArrow: null, // Oculta las flechas en pantallas pequeñas (xs)
          nextArrow: null,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <div className="container pt-3">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-7 col-xs-12 d-flex justify-content-center">
          <div
            className={`d-flex justify-content-center ${
              isMoreThanThreeEvents ? "col-12" : "col-12 col-md-12"
            }`}
          >
            <Slider {...settings} ref={sliderRef}>
              {eventos.map((evento) => (
                <div
                  className="event"
                  key={evento.event.id_evento}
                  onClick={() => handleClickEvento(evento)}
                >
                  <img
                  className="img-carrusel"
                    alt={`Foto Carrusel de ${evento.event.title}`}
                    src={`${
                      evento.event.imagen_destacados === null || evento.event.imagen_destacados === ""
                        ? `${process.env.PUBLIC_URL}/img/destacados/destacada8.webp`
                        : `${process.env.PUBLIC_URL}/img/destacados/${evento.event.imagen_destacados}`
                    }`}
                  />
                  <div className="event-child"
                  style={{ '--color-area': evento.event.areaColor }}>
                    <p>
                      {evento.event.title === null || evento.event.title === ""
                        ? ""
                        : evento.event.title}
                    </p>

                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="right-content col-md-5 col-xs-12 m-0">
          {eventoSeleccionado ? (
            <div className="right-content-container">
              <h2>Destacados</h2>
              <div className="right-principal-content">
                <h3>
                  {eventoSeleccionado["event"].title === null ||
                  eventoSeleccionado["event"].title === ""
                    ? ""
                    : eventoSeleccionado["event"].title}
                </h3>
                <p>
                  {eventoSeleccionado["event"].description_text === null ||
                  eventoSeleccionado["event"].description_text === ""
                    ? ""
                    : <p dangerouslySetInnerHTML={{ __html: eventoSeleccionado["event"].description_text }} />}
                    
                </p>
                <div className="right-date-location-container">
                  <div className="right-date-location">
                    <span>
                      <FaRegClock />
                    </span>
                    {eventoSeleccionado["event"].fecha_evento === null ||
                    eventoSeleccionado["event"].fecha_evento === ""
                      ? ""
                      : <DateDisplay date={eventoSeleccionado["event"].fecha_evento} />
                      }
                  </div>
                  <div className="right-date-location">
                    <span>
                      <FaLocationArrow />
                    </span>
                    {eventoSeleccionado["event"].ubicacion === null ||
                    eventoSeleccionado["event"].ubicacion === ""
                      ? ""
                      : eventoSeleccionado["event"].ubicacion}
                  </div>
                </div>
              </div>
              <div className="moredetails">
                <Link to={`/evento/${eventoSeleccionado["event"].urlname}`}>
                  <button>Ver más detalles</button>
                </Link>
              </div>
            </div>
          ) : (
            <p className="right-without-event">
              Selecciona un evento para ver toda su información
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
