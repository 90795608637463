import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Link from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import { apiUrlAced, urlRoot } from "../global";
import "./EventoDestacadoEstilos.css";

export default function EventoDestacado({
  areaEvent,
  nombreEvento,
  lugarEvento,
  urlEvento,
  urlImg,
  tipoEvento,
}) {
  const validaRuta = lugarEvento.search("http");
  const [areaData, setAreaData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [eventoNormal, seteventoNormal] = useState(true);

  useEffect(() => {
    if (areaEvent) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(apiUrlAced + "areas/" + areaEvent, options)
        .then((response) => response.json())
        .then((datos) => {
          setAreaData(datos.data.color);
        });
    }
    if (
      tipoEvento === "902101" ||
      tipoEvento === "902102" ||
      tipoEvento === "35839594891577"
    )
      seteventoNormal(false);

    setShowSpinner(false);
  }, [areaEvent, validaRuta, tipoEvento]);

  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: 5,
        width: "100%",
        display: "flex",
        backgroundColor: "white",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      {showSpinner && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}

      {!showSpinner && (
        <>
          <CardMedia
            component="img"
            sx={{ width: "85%", mt: 3, borderRadius: 5 }}
            image={
              urlImg
                ? `${process.env.PUBLIC_URL}/img/eventos/${urlImg}`
                : `${process.env.PUBLIC_URL}/img/test.jpg`
            }
            alt="test"
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              pl: "2%",
              pr: "2%",
              alignItems: "initial",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                flex: "1 0 auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="caja-1">
                <Typography component="div" sx={{ mb: 2, fontSize: "22px" }}>
                  <b>{nombreEvento}</b>
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "initial",
                    marginBottom: 0.5,
                    marginTop: 0.5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color=" "
                    component="div"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {!lugarEvento ||
                    lugarEvento.toLowerCase() ===
                      "off" ? null : lugarEvento.search("http") !== -1 ? (
                      <>
                        <Link />
                        {eventoNormal && (
                          <b title={"Evento en línea"}>Evento en línea</b>
                        )}
                      </>
                    ) : (
                      <>
                        <LocationOnIcon />
                        {eventoNormal && (
                          <b title={lugarEvento}>{lugarEvento}</b>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>
              </div>
              <div className="caja-2">
                <Box sx={{ display: "flex", justifyContent: "initial", mt: 2 }}>
                  <Button
                    style={{ backgroundColor: areaData }}
                    sx={{ p: "12px", borderRadius: 3 }}
                    href={
                      urlEvento.startsWith("https")
                        ? urlEvento
                        : urlRoot === "gestor_eventos"
                        ? "/" + urlRoot + "/evento/" + urlEvento
                        : "/evento/" + urlEvento
                    }
                    variant="contained"
                    target={
                      urlEvento.startsWith("https") ? "__blank" : undefined
                    }
                  >
                    Ver más detalles
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Box>
        </>
      )}
    </Card>
  );
}

export { EventoDestacado };
