import React, { useEffect, useState } from "react";
import { Campus } from "../../components/campus";
import { Navbar } from "../../components/Navbar";
import { apiUrlGestor } from "../../global";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Chip, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Pagination from "@mui/material/Pagination";
import { useParams } from "react-router-dom";
import Banner from "../../components/Banner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FiltrosComponent from "./filtrosComponent";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function callData(nivel, campusId, setEventos) {
  setEventos([]);
  axios
    .post(+"campuses/search", {
      grade: nivel,
      name: campusId,
    })
    .then(function (response) {
      const campusData = response.data.data;
      axios
        .get(apiUrlGestor + "obtenerEventos.php?c=" + campusData[0]["sf_key"])
        .then(function (response) {
          const eventoData = response.data.events;
          setEventos(eventoData); // Actualizar el estado con los datos recibidos
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    .catch(function (error) {
      console.log(error);
    });
}

// function buscar(busqueda, setEventos) {
//   setEventos("");
//   Agrega el parámetro 'busqueda'
//   const sfKey = localStorage.getItem("campusStorage");
//   console.log(campusData);
//   let __dataEventos;
//   __dataEventos = fetchPost(
//     "http://localhost/gestor_eventos/api/obtenerEventosBusqueda.php",
//     busqueda
//   );

//   console.log("busqueda");
//   console.log(busqueda);
//   fetch(apiUrlGestor + "/obtenerEventosBusqueda.php", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(busqueda), // Convertir objeto a JSON
//   })
//     .then(function (response) {
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       return response.json();
//     })
//     .then(function (data) {
//       const campusData = data.events;
//       console.log(data.events);
//       setEventos(campusData); // Actualizar el estado con los datos recibidos
//     })
//     .catch(function (error) {
//       console.error("Error:", error);
//       // ...
//     });
// }

function CampusView() {
  const { busqueda } = useParams();
  const eventsPerPage = 10; // Cambia esto según tu preferencia
  const [currentPage, setCurrentPage] = useState(1);
  const [campus, setCampus] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [eventos, setEventos] = useState([]);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = eventos.slice(indexOfFirstEvent, indexOfLastEvent);
  const [imgName, setImgName] = useState("");
  const [chipsData, setChipsData] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");

  const buscar = (busqueda, setEventos) => {
    setEventos("");
    // Agrega el parámetro 'busqueda'
    // const sfKey = localStorage.getItem("campusStorage");
    // console.log(campusData);
    // let __dataEventos;
    // __dataEventos = fetchPost(
    //   "http://localhost/gestor_eventos/api/obtenerEventosBusqueda.php",
    //   busqueda
    // );
    fetch(apiUrlGestor + "/obtenerEventosBusqueda.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(busqueda),
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(function (data) {
        const campusData = data.events;
        setEventos(campusData);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const handleCampusChangeInParent = (selectedCampus, nivel) => {
    callData(nivel, selectedCampus, setEventos);
  };
  const handleDelete = (titulo, tipo) => {
    let data = {
      titulo: titulo,
      tipo: tipo,
    };
    removeChipByType(tipo);
    setButtonClicked(data);
  };

  const removeChipByType = (tipo, removeChipByType = null) => {
    if (removeChipByType != null) {
      const updatedChipsData = removeChipByType.filter(
        (chip) => chip.tipo !== tipo
      );
      setChipsData(updatedChipsData);
      buscar(updatedChipsData, setEventos);
    } else {
      let updatedChipsData = chipsData.filter((chip) => chip.tipo !== tipo);
      if (tipo === "nivel") {
        updatedChipsData = updatedChipsData.filter(
          (chip) => chip.tipo !== "campus"
        );
      }
      setChipsData(updatedChipsData);
      buscar(updatedChipsData, setEventos);
    }
  };

  const addChip = (
    titulo,
    tipo,
    flag = null,
    __newChip = null,
    fecha = null
  ) => {
    if (__newChip != null) {
      let __chipsData = [];
      __chipsData = [...chipsData, ...__newChip];
      setChipsData(__chipsData);
      buscar(__chipsData, setEventos);
    } else {
      const existingChipIndex = chipsData.findIndex(
        (chip) => chip.tipo === tipo
      );
      if (existingChipIndex !== -1) {
        const updatedChipsData = chipsData.map((chip, index) => {
          if (index === existingChipIndex) {
            return {
              ...chip,
              titulo: titulo,
              fecha: fecha !== null ? fecha : undefined,
            };
          }
          return chip;
        });
        if (tipo === "nivel") {
          removeChipByType("campus", updatedChipsData);
        } else {
          setChipsData(updatedChipsData);
          buscar(updatedChipsData, setEventos);
        }
      } else {
        const newChip = {
          titulo: titulo,
          tipo: tipo,
          fecha: fecha !== null ? fecha : undefined, // Agregar fecha solo si no es null
        };
        const updatedChipsData = [...chipsData, newChip];
        setChipsData(updatedChipsData);
        if (flag == null) {
          buscar(updatedChipsData, setEventos);
        }
      }
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchImgName = () => {
    fetch(apiUrlGestor + "obtenerImg.php")
      .then((response) => response.json())
      .then((data) => {
        setImgName(data["data"][0]["imagen"]);
      })
      .catch((error) => {
        console.log("Error al obtener los banners", error);
      });
  };

  useEffect(() => {
    const fetchEventData = async () => {
      moment.locale("es");
      addChip(busqueda, "busqueda");
      setCampus(localStorage.getItem("campusStorageName"));
      setSelectedGrade(localStorage.getItem("nivelStorage"));
      fetchImgName();
    };

    fetchEventData();
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [busqueda]);

  return (
    <div>
      <Navbar
        onChangeCampus={handleCampusChangeInParent}
        setEventos={setEventos}
        __nivel={selectedGrade}
        __campus={""}
      />
      <Banner imgName={imgName} campus={"off"} />
      <Box sx={{ flexGrow: 1, mt: 2.5 }}>
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          container
          spacing={2}
        >
          {" "}
          <Grid item xs={3}>
            <Grid item xs={12}>
              <Typography
                component="div"
                variant="h4"
                sx={{
                  mb: 2.5,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <FormatListBulletedIcon sx={{ fontSize: "2.4rem" }} />
                Filtros
              </Typography>
              <FiltrosComponent
                buttonClicked={buttonClicked}
                removeChipByType={removeChipByType}
                onChangeCampus={handleCampusChangeInParent}
                addChip={addChip}
                __nivel={selectedGrade}
                __campus={campus}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <Typography component="div" variant="h4" sx={{ mb: 2.5 }}>
                <b>Elige tu evento</b>
              </Typography>
              {chipsData.map(
                (chipData, key) =>
                  !chipData.extraParam && (
                    <Chip
                      key={key}
                      label={chipData.titulo}
                      variant="outlined"
                      onDelete={() =>
                        handleDelete(chipData.titulo, chipData.tipo)
                      }
                    />
                  )
              )}
            </Grid>

            {eventos.length === 0 ? ( // Check if there are no events
              <Typography variant="body1">
                No hay eventos disponibles.
              </Typography>
            ) : (
              currentEvents.map((evento, key) => (
                <Campus
                  key={key}
                  areaEvent={evento.event.area}
                  nombreEvento={evento.event.title}
                  subtituloEvento={evento.event.description_text}
                  urlImg={evento.event.imagen}
                  fechaEvento={capitalizeFirstLetter(
                    moment(evento.event.fecha_evento).format(
                      "dddd, D [de] MMMM"
                    )
                  )}
                  ubicacionEvento={evento.event.ubicacion}
                  idEvento={evento.event.urlname}
                />
              ))
            )}
            <Pagination
              count={Math.ceil(eventos.length / eventsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "5%" }}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default CampusView;
