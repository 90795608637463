import { apiUrlAced } from "./global";

export async function fetchGet(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function fetchPost(url, data) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}
/**
 * Esta funcion obtiene los campus depiendiendo del nivel o el detalle de un campus en caso de enviar el alias
 * @param nivel profesinal | prepatec
 * @param alias Alias del campus
 * @returns retorna los campus, en caso de tener alias retorna los datos del campus enviado en el alias
 */
export async function obtenerCampus(nivel, alias = null) {
  try {
    const apiUrl = apiUrlAced + "campuses/search";
    let data = {};

    if (alias) {
      data = { ...data, grade: nivel, alias };
    } else {
      data = { ...data, grade: nivel };
    }
    console.log(data);
    const datos = await fetchPost(apiUrl, data);

    if (alias) {
      const cambiosDeNombres = {
        Cumbres: "MTY-Cumbres",
        "Santa Catarina": "MTY-Santa Catarina",
        "Eugenio Garza Sada": "MTY-Eugenio Garza Sada",
        "Eugenio Garza Lagüera": "MTY-Eugenio Garza Lagüera",
        "Valle Alto": "MTY-Valle Alto",
      };

      datos.data.forEach((campus) => {
        if (cambiosDeNombres.hasOwnProperty(campus.name)) {
          campus.name = cambiosDeNombres[campus.name];
        }
      });

      datos.data.sort((a, b) => {
        if (a.name === "Monterrey") {
          return -1;
        }
        if (b.name === "Monterrey") {
          return 1;
        }
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }

    return datos.data;
  } catch (error) {
    console.error("Error fetching campuses:", error);
    throw error;
  }
}

export function formatDataCampus(summaryText, defaultValue, options) {
  return {
    summaryText: summaryText,
    defaultValue: defaultValue,
    options: options.map((option) => ({
      value: option.sf_key,
      label: option.name,
    })),
  };
}
